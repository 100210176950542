import { useState } from 'react';
import { useHistory } from 'react-router-dom';
import axios from 'axios';

const Home = () => {
  const [fullUrl, setFullUrl] = useState('');
  const [isValid, setIsValid] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const history = useHistory();

  const handleSubmit = (e) => {
    e.preventDefault();
    setIsLoading(true);

    axios
      .post('/api/urls/add', { fullUrl })
      .then((res) => {
        history.push({
          pathname: '/pendek',
          state: { url: res.data },
        });
      })
      .catch((err) => {
        setIsValid(false);
        setTimeout(() => setIsValid(true), 1000);
        setIsLoading(false);
        setFullUrl('');
        console.log(err);
      });
  };

  return (
    <>
      {!isValid && (
        <div className='overlay-failed'>
          <div className='overlay-text'>URL Not Valid</div>
        </div>
      )}
      <div className='center'>
        <h1 className='title'>pendek.me</h1>
        <form onSubmit={(e) => handleSubmit(e)}>
          <input
            id='full-url'
            type='text'
            value={fullUrl}
            onChange={(e) => setFullUrl(e.target.value)}
            placeholder='Enter URL'
            autoComplete='off'
          />
          {isLoading && <p>Loading...</p>}
          <button id='shortenButton' type='submit' className='btn btn-primary'>
            Shorten URL
          </button>
        </form>
      </div>
    </>
  );
};

export default Home;
